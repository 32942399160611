html {
  font-size: 14px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #FCFCFC;
}

#root {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  font-family: 'Fira Sans';
}

p a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: underline solid;
}

.fill-current path,
.fill-current circle {
  fill: currentColor;
}

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}

.daterangepicker {
  color: #2E384D !important;
  width: max-content !important;
  font-family: 'Fira Sans' !important;
}
.daterangepicker .calendar-table > table > tbody > tr > td.active,
.daterangepicker .calendar-table > table > tbody > tr > td.active.start-date,
.daterangepicker .calendar-table > table > tbody > tr > td.active.end-date,
.daterangepicker .calendar-table > table > tbody > tr > td.active.off.ends.end-date {
  background-color: #6D0FB1;
}
.daterangepicker .calendar-table > table > tbody > tr > td.in-range,
.daterangepicker .calendar-table > table > tbody > tr > td.active.off.ends {
  background-color: #F0E7F7;
}
